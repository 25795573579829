<template>
  <product :title="title"
    :swiper-data="swiperData"
    :slogan="slogan"
    :price="price"
    :colors="colors"
    :detail-images="detailImages"
    :param-images="paramImages"
    :video="video"
    buy-link="https://j.youzan.com/aSjjKh"
    is-switch-title
    is-switch-slogan
    is-switch-price
    is-bus-city1
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'nexo',
  components: {
    Product
  },
  data () {
    return {
      title: [
        'Amazfit 智能手表 2',
        'Amazfit 智能手表2  ECG'
      ],
      slogan: [
        '奢华微晶锆陶瓷中框 | 2.5D 水漾屏 | 4G LTE 自由通话上网 <br/> 个性化表盘商店 | 内置独立 QQ、QQ 音乐 | 蓝牙通话 <br/> NFC公交门禁',
        'ECG 非处方心电图 | AI 自动甄别心律不齐(含房颤) | 4G LTE 通话上网<br/> 奢华微晶锆陶瓷中框 | 2.5D 水漾屏 | 个性化表盘商店 <br/>内置独立 QQ、QQ 音乐| 语音控制 | 蓝牙通话 | NFC公交门禁'
      ],
      colors: [
        '标准版',
        'ECG版'
      ],
      price: [
        '999',
        '1299'
      ],
      video: {
        url: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/videos/nexo.mp4',
        poster: CDN_URL + '/images/poster/nexo.png'
      },
      swiperData: [
        [
          CDN_URL + '/images/product/nexo/swiper/1.png',
          CDN_URL + '/images/product/nexo/swiper/2.png',
          CDN_URL + '/images/product/nexo/swiper/3.png',
          CDN_URL + '/images/product/nexo/swiper/4.png'
        ],
        [
          CDN_URL + '/images/product/nexo/swiper/5.png',
          CDN_URL + '/images/product/nexo/swiper/6.png',
          CDN_URL + '/images/product/nexo/swiper/7.png',
          CDN_URL + '/images/product/nexo/swiper/8.png'
        ]
      ],
      detailImages: [
        CDN_URL + '/images/product/nexo/detail/s01.jpg',
        CDN_URL + '/images/product/nexo/detail/s02.jpg',
        CDN_URL + '/images/product/nexo/detail/s03.jpg',
        CDN_URL + '/images/product/nexo/detail/s04.jpg',
        CDN_URL + '/images/product/nexo/detail/s05.jpg',
        CDN_URL + '/images/product/nexo/detail/s06.jpg',
        CDN_URL + '/images/product/nexo/detail/s07.jpg',
        CDN_URL + '/images/product/nexo/detail/s08.jpg',
        CDN_URL + '/images/product/nexo/detail/s09.jpg',
        CDN_URL + '/images/product/nexo/detail/s10.jpg',
        CDN_URL + '/images/product/nexo/detail/s11.jpg',
        CDN_URL + '/images/product/nexo/detail/s12.jpg',
        CDN_URL + '/images/common/zepp-black.jpg',
        CDN_URL + '/images/product/nexo/detail/s13.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/nexo/detail/s14.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
